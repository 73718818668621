// assets/front.js
import './bootstrap.js';
import './styles/front.scss';

import { Tooltip, Collapse, Carousel, Dropdown, Modal, Tab } from 'bootstrap';

document.addEventListener('turbo:load', function (e) {
	// Initialize Bootstrap tooltips
	let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	tooltipTriggerList.map(function (tooltipTriggerEl) {
		return new Tooltip(tooltipTriggerEl);
	});

	// Initialize Bootstrap collapse
	let collapseTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="collapse"]'));
	collapseTriggerList.map(function (collapseTriggerEl) {
		return new Collapse(collapseTriggerEl);
	});

	// Initialize Bootstrap tabs
	let tabTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tab"]'));
	tabTriggerList.map(function (tabTriggerEl) {
		return new Tab(tabTriggerEl);
	});

	// Initialize Bootstrap carousel
	let carouselTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="carousel"]'));
	carouselTriggerList.map(function (carouselTriggerEl) {
		return new Carousel(carouselTriggerEl);
	});

	// Initialize Bootstrap dropdowns
	let dropdownTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'));
	dropdownTriggerList.map(function (dropdownTriggerEl) {
		return new Dropdown(dropdownTriggerEl);
	});

	// Initialize Bootstrap modals
	let modalTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="modal"]'));
	modalTriggerList.map(function (modalTriggerEl) {
		return new Modal(modalTriggerEl);
	});
});